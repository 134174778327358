import cx from 'classix';
import { type LinkProps } from 'next/link';
import {
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type RefObject,
} from 'react';
import { FaCheck } from 'react-icons/fa6';

import { Link } from '@/i18n/routing';
import Loader from '@/ui/loader/loader';

import styles from './button.module.scss';

type ButtonVariant = 'outlined' | 'filled' | 'ghost';
type ButtonSize = 'lg' | 'md' | 's';
type ButtonFontSize = 'm' | 's' | 'xs';
type ButtonShade = 'dark' | 'light';

interface ButtonBaseProps {
  size?: ButtonSize,
  fullWidth?: boolean,
  variant?: ButtonVariant,
  shade?: ButtonShade,
  fontSize?: ButtonFontSize,
  isLoading?: boolean,
  isSuccess?: boolean,
  successMessage?: string,
  ref?: RefObject<HTMLButtonElement>;
  withFocus?: boolean;
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, ButtonBaseProps {
  element: 'button';
}

type NextLink = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> & LinkProps;

// type HtmlLink = AnchorHTMLAttributes<HTMLAnchorElement>;

export interface ButtonLinkProps extends NextLink, ButtonBaseProps {
  element: 'link';
}

export interface ButtonNativeLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement>, ButtonBaseProps {
  element: 'nativeLink';
}

export default function Button(props: ButtonProps | ButtonLinkProps | ButtonNativeLinkProps) {
  const {
    children,
    // eslint-disable-next-line unicorn/no-keyword-prefix
    className,
    element,
    fontSize = 'm',
    fullWidth,
    isLoading = false,
    isSuccess = false,
    onClick,
    shade = 'dark',
    size = 'md',
    successMessage = null,
    variant = 'filled',
    withFocus = false,
  } = props;

  const buttonStyles = cx(
    className,
    styles.base,
    styles[`size_${size}`],
    styles[`variant_${variant}`],
    styles[`shade_${shade}`],
    styles[`font_${fontSize}`],
    fullWidth && styles.size_full,
    isSuccess && styles.base__isSuccess,
    withFocus && styles.base__withFocus,
  );

  if (element === 'link') {
    const {
      href,
      prefetch,
    } = props;

    return (
      <Link
        className={buttonStyles}
        href={href}
        prefetch={prefetch}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }

  if (element === 'nativeLink') {
    const { href } = props;

    return (
      <a
        className={buttonStyles}
        href={href}
        onClick={onClick}
        data-href="test"
      >
        {children}
      </a>
    );
  }

  const {
    disabled,
    name,
    ref,
    type = 'button',
  } = props;

  return (
    <button
      ref={ref}
      className={buttonStyles}
      name={name}
      onClick={onClick}
      type={type}
      disabled={disabled ?? (isLoading || isSuccess)}
      aria-disabled={disabled ?? (isLoading || isSuccess)}
      aria-label={name}
    >
      {isSuccess ? (
        successMessage ? (
          <div className={styles.buttonSuccessMessage}>{successMessage}</div>
        ) : (
          // eslint-disable-next-line canonical/prefer-react-lazy
          <FaCheck className={styles.buttonSuccessIcon} />
        )
      ) : (
        isLoading ? <Loader /> : children
      )}
    </button>
  );
}
